.margin-bottom-lvl0 {
  margin-bottom: 0;
}

.margin-bottom-lvl1 {
  margin-bottom: 8px;
}

.margin-bottom-lvl2 {
  margin-bottom: 20px;
}

.margin-bottom-lvl3 {
  margin-bottom: 30px;
}

.margin-bottom-lvl4 {
  margin-bottom: 40px;
}

.margin-bottom-lvl5 {
  margin-bottom: 50px;
}

.margin-top-lvl0 {
  margin-top: 0;
}

.margin-top-lvl1 {
  margin-top: 8px;
}

.margin-top-lvl2 {
  margin-top: 20px;
}

.margin-top-lvl3 {
  margin-top: 30px;
}

.margin-top-lvl4 {
  margin-top: 40px;
}

.margin-top-lvl5 {
  margin-top: 50px;
}
