@value black:                #242627;
@value dark-grey:            #5E5E5E;
@value grey:                 #AFAFAF;
@value light-grey:           #D7D7D7;
@value very-light-grey:      #EBEFEB;
@value off-white:            #F6F6F6;
@value white:                #FFFFFF;
@value dark-green:           #3B7C50;
@value primary-green:        #49915D;
@value medium-green:         #56BE75;
@value light-green:          #B9E6AF;
@value very-light-green:     #E5F2DC;
@value red:                  #D50035;
@value purple:               #743B72;
@value pale-green:           #e5f2dc;
@value light-pink:           #FBE5EA;

.color-black {
  color: black;
}

.color-dark-grey {
  color: dark-grey;
}

.color-grey {
  color: grey;
}

.color-light-grey {
  color: light-grey;
}

.color-very-light-grey {
  color: very-light-grey;
}

.color-off-white {
  color: off-white;
}

.color-white {
  color: white;
}

.color-dark-green {
  color: dark-green;
}

.color-primary-green {
  color: primary-green;
}

.color-medium-green {
  color: medium-green;
}

.color-light-green {
  color: light-green;
}

.color-very-light-green {
  color: very-light-green;
}

.color-red {
  color: red;
}

.color-purple {
  color: purple;
}

.color-pale-green {
  color: pale-green;
}

.background-color-black {
  background-color: black;
}

.background-color-dark-grey {
  background-color: dark-grey;
}

.background-color-grey {
  background-color: grey;
}

.background-color-light-grey {
  background-color: light-grey;
}

.background-color-very-light-grey {
  background-color: very-light-grey;
}

.background-color-off-white {
  background-color: off-white;
}

.background-color-white {
  background-color: white;
}

.background-color-dark-green {
  background-color: dark-green;
}

.background-color-primary-green {
  background-color: primary-green;
}

.background-color-medium-green {
  background-color: medium-green;
}

.background-color-light-green {
  background-color: light-green;
}

.background-color-very-light-green {
  background-color: very-light-green;
}

.background-color-red {
  background-color: red;
}

.background-color-purple {
  background-color: purple;
}

.background-color-pale-green {
  background-color: pale-green;
}
