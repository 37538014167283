@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

@value colors: "./colors.module.css";
@value black, dark-grey, dark-green from colors;

.h1 {
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 32px;
  font-weight: 400;
  line-height: 46px;
}

.h2 {
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
}

.h3 {
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.h4 {
  font-family: 'Open Sans', sans-serif;
  color: dark-grey;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.h5 {
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.body_text {
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.label_text {
  font-family: 'Open Sans', sans-serif;
  color: dark-grey;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.meta_text,
.supporting_text {
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.supporting_text {
  color: dark-grey;
}

.semibold_text {
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.mini_link {
  font-family: 'Open Sans', sans-serif;
  color: dark-green;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
