@value colors: "../../css/colors.module.css";
@value color-black, light-grey, primary-green from colors;

footer {
  width: 100%;
  box-sizing: border-box;
  background-color: #ebefeb;
  padding: 20px 25px;
  margin: 0;
  display: flex;
  font-family: "Open Sans";
}
.footer_sub_nav p {
  font-size: 14px;
  color:color-black;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 1rem;
}
.footer_nav {
  margin: 0;
}
.footer_nav a{
  margin: 0;
}
.footer_sub_nav {
  border-bottom: 1px solid light-grey;
  display: flex;
  width: 100%;
}
.footer_sub_nav a {
  padding-left: 15px;
}
.footer_bottom_links a {
  padding-left: 15px;
}
.copyright {
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: color-black;
  width: 100%;
  display: flex;
  margin: 0;
}
.footer_bottom_links {
  padding-left: 0;
}
.footer_nav a {
  text-decoration: underline;
  cursor: pointer;
  color: primary-green;
  line-height: 1.4;
  font-size: 14px;
}